
import TopMenu from "@/components/common/TopMenu.vue";
import { setCustomProperty } from "~/utils";
export default {
  components: {
    TopMenu,
  },
  methods: {
    setHeight(val) {
      setCustomProperty("--header-height", `${val.contentRect.height}px`);
    },
  },
};
